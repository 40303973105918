<template>
    <div>
        <div class="SecondTitle">
            <span>{{ $t("enterNumber.secondTitle") }}</span>
        </div>
        <div class="tips" 
            v-html='$t("enterNumber.tips").format(privacyPolicyLink)'
        ></div>
        <van-cell-group class="form-cell" style="margin-top: 20px">
            <van-form>
                <div style="margin-top: 15px">
                    <van-field
                        required
                        v-model="firstName"
                        style="border-bottom: 1px solid #f2f3f5"
                        :placeholder="this.$t('registration.formValueFirstName')"
                        :error-message="errMessageFirstName"
                        @blur="validatorFirstName"
                        @input="validatorFirstName"
                    />
                </div>
                <div class="phone_class">
                    <div style="width:25%;display: inline-block;vertical-align: middle;margin: 0 auto">
                        <van-field type="number" v-model="phoneFirstDigit" center :maxlength="3" ref="firstDigit"/>
                    </div>
                    <div style="width:5%;display: inline-block;vertical-align: middle;margin: 0 auto">
                        <van-divider :style="{ borderColor: '#000000', margin: '0' }" />
                    </div>
                    <div style="width:30%;display: inline-block;vertical-align: middle;margin: 0 auto">
                        <van-field
                            type="number"
                            v-model="phoneSecondDigit"
                            center
                            :maxlength="4"
                            ref="secondDigit"
                        />
                    </div>
                    <div style="width:5%;display: inline-block;vertical-align: middle;margin: 0 auto">
                        <van-divider :style="{ borderColor: '#000000', margin: '0' }" />
                    </div>
                    <div style="width:30%;display: inline-block;vertical-align: middle;margin: 0 auto">
                        <van-field type="number" v-model="phoneThirdDigit" center :maxlength="4" ref="thirdDigit"/>
                    </div>
                </div>

                <div style="color:#ff0000; font-size: small;margin-left: 16px;margin-bottom: 5px;">{{ this.errMessagePhone }}</div>

                <van-divider :style="{ borderColor: '#000', margin: '0' }" />

                <!-- <div class="second-tips">
                    <div>
                        <input class="agreeCheckBox" type="checkbox" v-model="agreeChecked" />
                    </div>
                    <div
                        class="agreeStatement"
                        v-html='$t("enterNumber.readPrivacyPolicy").format(brandConfig.display, privacyPolicyLink)'
                    ></div>
                </div> -->
                <van-button
                    :disabled="check() || this.loading"
                    size="large"
                    round
                    native-type="submit"
                    class="button-getotp"
                    @click="toOtp"
                    :loading="loading"
                >{{ $t("enterNumber.getOTP") }}</van-button>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script>
import {
    Cell,
    CellGroup,
    Form,
    DropdownMenu,
    DropdownItem,
    Divider,
    Button,
    Field,
    NumberKeyboard,
    PasswordInput,
    Toast,
    Dialog
} from "vant";
import { Image as VanImage } from "vant";
import { generateOtp,searchCustomer } from "@/api/eformApi";
import mixins from "./mixin/Registration.js";
export default {
    name: "EnterNumber",
    mixins: [mixins],
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Form.name]: Form,
        [NumberKeyboard.name]: NumberKeyboard,
        [PasswordInput.name]: PasswordInput,
        [VanImage.name]: VanImage,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Divider.name]: Divider,
        [Button.name]: Button,
        [Field.name]: Field,
        [Dialog.Component.name]: Dialog.Component,
    },
    data() {
        return {
            firstName: "",
            namePattern: /^[\uac00-\ud7ffA-Za-z]{0,}$/,
            showKeyboard: false,
            pattern:
                /^(010|011|016|017|018|019)[-]{1}[0-9]{3,4}[-]{1}[0-9]{4}$/,
            agreeChecked: false,
            errMessagePhone: "",
            loading: false,
            phoneFirstDigit: "",
            phoneSecondDigit: "",
            phoneThirdDigit: "",
            errMessageFirstName:"",
        };
    },
    computed: {
        privacyPolicyLink() {
            if (this.brandConfig.privacyPolicy) {
                return `<span><a href="${
                    this.brandConfig.privacyPolicy
                }">${this.$t("enterNumber.privacyPolicy")}</a></span>`;
            } else {
                return `<span>${this.$t("enterNumber.privacyPolicy")}</span>`;
            }
        },
        currentLocale() {
            return this.$root.$i18n.locale;
        },
        phoneNumber() {
            return (
                this.phoneFirstDigit +
                "-" +
                this.phoneSecondDigit +
                "-" +
                this.phoneThirdDigit
            );
        },
    },
    watch: {
        currentLocale: {
            handler() {
                if (this.errMessagePhone != "") {
                    this.validatorPhone();
                }
                if (this.errMessageFirstName != "") {
                    this.validatorFirstName();
                }
            },
        },
        phoneFirstDigit: {
            handler() {
                if (this.validatorPhoneFirstDigit()) {
                    this.$refs.secondDigit.focus();
                }
            },
        },
        phoneSecondDigit: {
            handler() {
                if (this.phoneSecondDigit.length == 4) {
                    this.$refs.thirdDigit.focus();
                }
            },
        }
    },
    methods: {
        validatorFirstName() {
            this.errMessageFirstName = "";
            if (this.firstName == "" || this.firstName.trim() == "") {
                this.errMessageFirstName = this.$t(
                    "registration.rulerFirstname"
                );
                return false;
            }
            if (!this.namePattern.test(this.firstName)) {
                this.errMessageFirstName = this.$t(
                    "registration.rulerFirstname1"
                );
                return false;
            }
            return true;
        },
        validatorPhoneFirstDigit() {
            var arr = ["010", "011", "016", "017", "018", "019"];
            if (
                this.phoneFirstDigit.length == 3 &&
                !arr.includes(this.phoneFirstDigit)
            ) {
                this.errMessagePhone = this.$t("enterNumber.phoneRuleMessage");
                return false;
            } else {
                this.errMessagePhone = "";
            }
            return this.phoneFirstDigit.length == 3
        },
        validatorPhone() {
            this.errMessagePhone = "";
            if (
                this.phoneNumber == "" ||
                this.phoneNumber.trim() == "" ||
                !this.pattern.test(this.phoneNumber)
            ) {
                this.errMessagePhone = this.$t("enterNumber.phoneRuleMessage");
                console.log("errMessagePhone: " + this.errMessagePhone);
                return false;
            }
            return true;
        },
        toOtp() {
            if (!this.validatorPhone()  || !this.validatorFirstName()) {
                return;
            }
            this.loading = true;
            this.firstName = this.firstName.trim();

            searchCustomer({
                country: this.$route.params.countryPath,
                brandName: this.brandConfig.display,
                storeNo: this.$route.query.storeId,
                name: this.firstName,
                mobile: this.phoneNumber,
            }).then((res) => {
                if (res.success) {
                    if (res.data == 1) {
                        Dialog.alert({
                            message: this.$t("registration.alreadyRP"),
                            confirmButtonText: this.$t("registration.confirm"),
                        });
                        this.loading = false;
                    } else {
                        this.getOTP();
                    }
                } else {
                    Toast.fail({
                            type: "fail",
                            className: 'noticeWidth',
                            message: this.$t("enterNumber.toastFail"),
                    });
                    this.loading = false;
                }
            });
        },
        getOTP() {
            generateOtp({
                phoneno: this.phoneNumber,
                country: this.$route.params.countryPath,
                brandName: this.brandConfig.display,
                storeNo: this.$route.query.storeId,
                brandCode: this.$route.params.brand,
            })
                .then((res) => {
                    this.loading = false;
                    if (res.success) {
                        Toast.success({
                            type: "success",
                            className: 'noticeWidth',
                            message: this.$t("enterNumber.toastSuccess"),
                        });
                        this.$router.push(
                            {
                                name: 'EnterOpt',
                                params: {
                                    lang: this.$route.params.lang,
                                    brand: this.$route.params.brand,
                                    country: this.$route.params.countryPath,
                                    sessionKey: res.data.session_key,
                                    firstName: this.firstName,
                                    phoneNumber: this.phoneNumber,
                                },
                                query: {
                                    storeId: this.$route.query.storeId,
                                }
                            }
                        );
                    } else {
                        Toast.fail({
                            type: "fail",
                            className: 'noticeWidth',
                            message: this.$t("enterNumber.toastFail"),
                        });
                    }
                })
                .catch(() => {
                    Toast.fail({
                            type: "fail",
                            className: 'noticeWidth',
                            message: this.$t("enterNumber.toastFail"),
                        });
                    this.loading = false;
                });
        },
        check() {
            switch (this.$route.params.countryPath) {
                case "KR":
                    if (
                        this.phoneNumber.length === 0 ||
                        !this.pattern.test(this.phoneNumber) || this.firstName == "" || this.firstName.trim() == ""
                        || !this.namePattern.test(this.firstName)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                default:
                    return true;
            }
        },
    },
};
</script>

<style scoped>
* {
    font-family: Helvetica-Regular !important;
}

.form-cell >>> .van-hairline, .van-hairline--bottom, .van-hairline--left, .van-hairline--right, .van-hairline--surround, .van-hairline--top, .van-hairline--top-bottom{
    position: initial;
}
>>> .van-popup--top {
    width: 25%;
    padding-left: 16px;
}

.button-getotp {
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 4%;
}

>>> .van-password-input__item {
    border-radius: 5px;
}
.custom-class {
    color: #ff0000;
}

.phone_class >>> .van-field__control {
    border: 1px solid lightgray;
    border-radius: 5px;
    text-align: center;
}

</style>

<style>
.noticeWidth {
    width: max-content !important;
}
</style>
